<template>
  <v-container id="merchandise-for-sale" fluid tag="section">
    <edit-merchandise-item-form v-model="editMerchandiseItem" :merchandise-item="merchandiseItem" @success="editRefresh=Date.now(),success()" @fail="fail" />
    <div class="mt-2 mb-2 text-right">
      <span v-if="$can('create', 'Purchase')">
        <v-btn color="secondary" :disabled="editMerchandiseItem" :loading="editMerchandiseItem" @click.stop="merchandiseItem={type: 'purchase'},editMerchandiseItem=true">
          <v-icon class="pr-2">
            mdi-package-variant-closed-plus
          </v-icon>
          {{ $t('purchase.merchandise.items.purchase.button.new') }}
        </v-btn>
      </span>
    </div>
    <merchandise-items type="purchase" :refresh="Math.max(refresh, editRefresh)" @success="success" @fail="fail" />
  </v-container>
</template>

<script>
  import EditMerchandiseItemForm from '@/views/forms/merchandise/EditMerchandiseItemForm';
  import MerchandiseItems from '@/views/components/business/purchase/MerchandiseItems';

  export default {
    name: 'MerchandiseForSale',
    components: { EditMerchandiseItemForm, MerchandiseItems },
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        loading: false,
        merchandiseItem: {},
        editMerchandiseItem: false,
        editRefresh: null
      };
    },
    methods: {
      success () {
        this.$emit('success');
      },
      fail (error, errorMessage) {
        this.$emit('fail', error, errorMessage);
      }
    }
  };
</script>
