<template>
  <v-container id="table-merchandises-to-check" fluid tag="section">
    <edit-merchandise-item-form v-model="editMerchandiseItem" :merchandise-item="merchandiseItem" @success="editRefresh=Date.now(),refreshData()" @fail="fail" />
    <div class="mt-2 mb-2 text-right">
      <span v-if="$can('create', 'Purchase')" class="mr-2">
        <v-btn color="secondary" :disabled="editMerchandiseItem" :loading="editMerchandiseItem" @click.stop="merchandiseItem={type: 'check', quantity: 1},editMerchandiseItem=true">
          <v-icon class="pr-2">
            mdi-package-variant-closed-check
          </v-icon>
          {{ $t('purchase.merchandise.items.check.button.new') }}
        </v-btn>
      </span>
    </div>
    <v-card>
      <v-card-title>
        {{ $t('purchase.merchandise.items.check.table.action') }}
        <v-spacer />
        <v-text-field v-model="table.search" append-icon="mdi-magnify" :label="$t('purchase.merchandise.items.check.table.search')" single-line hide-details />
        <v-spacer />
        <table-exporter :headers="table.headers" :items="merchandisesToCheck" />
        <table-printer element-id="table-merchandise-to-check" />
      </v-card-title>
      <v-data-table :headers="table.headers" :options="table.options" :items="merchandisesToCheck" :search="table.search" :loading="loading" dense>
        <template #[`item.supplier.name`]="{ item }">
          <span><a :href="item.supplier.website" target="_blank">{{ item.supplier.name }}</a></span>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn small rounded color="accent" :disabled="loading" :loading="loading" @click.stop="merchandiseItem={type: 'check', merchandise: item.merchandise, supplier: item.supplier, quantity: 1},editMerchandiseItem=true">
            <v-icon medium dark :title="$t('purchase.merchandise.items.check.button.new')">
              mdi-plus
            </v-icon>
          </v-btn>
          &nbsp;
          <v-btn small rounded color="red" :disabled="loading" :loading="loading"
                 @click.stop="merchandiseItem={type: 'check', merchandise: item.merchandise, supplier: item.supplier, quantity: 1, packagingPriceTaxExcluded: null, unitPriceTaxExcluded: null},
                              editMerchandiseItem=true"
          >
            <v-icon medium dark :title="$t('purchase.merchandise.items.check.button.cancel')">
              mdi-close
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <merchandise-items type="check" :refresh="Math.max(refresh, editRefresh)" @fail="fail" />
  </v-container>
</template>

<script>
  import TableExporter from '@/views/components/common/TableExporter';
  import TablePrinter from '@/views/components/common/TablePrinter';
  import EditMerchandiseItemForm from '@/views/forms/merchandise/EditMerchandiseItemForm';
  import MerchandiseItems from '@/views/components/business/purchase/MerchandiseItems';

  export default {
    name: 'MerchandiseToCheck',
    components: { EditMerchandiseItemForm, MerchandiseItems, TableExporter, TablePrinter },
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        loading: false,
        table: {
          headers: [
            {
              text: this.$i18n.t('purchase.merchandise.items.check.table.column.merchandise'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'merchandise.name'
            },
            {
              text: this.$i18n.t('purchase.merchandise.items.check.table.column.supplier'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'supplier.name'
            },
            {
              text: '',
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'actions',
              width: '160px'
            }
          ],
          options: {
            page: 1,
            itemsPerPage: -1,
            sortBy: ['supplier.name'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            multiSort: false,
            mustSort: true
          },
          search: ''
        },
        merchandisesToCheck: [],
        merchandiseItems: [],
        merchandiseItem: {},
        editMerchandiseItem: false,
        editRefresh: null
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'merchandise/checking?management=manual', { timeout: 60000 });
          if (response.data !== undefined && response.data.merchandisesToCheck !== undefined) {
            this.merchandisesToCheck = response.data.merchandisesToCheck;
            this.$emit('success');
          }
        } catch (error) {
          this.fail(error, this.$i18n.t('common.error.default'));
        }
        this.loading = false;
      },
      fail (error, errorMessage) {
        this.$emit('fail', error, errorMessage);
      }
    }
  };
</script>
