var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"table-merchandises","fluid":"","tag":"section"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('purchase.merchandise.list.title'))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('purchase.merchandise.list.table.search'),"single-line":"","hide-details":""},model:{value:(_vm.table.search),callback:function ($$v) {_vm.$set(_vm.table, "search", $$v)},expression:"table.search"}}),_c('v-spacer'),_c('table-exporter',{attrs:{"headers":_vm.table.headers,"items":_vm.merchandises}}),_c('table-printer',{attrs:{"element-id":"table-merchandises"}})],1),_c('v-data-table',{attrs:{"headers":_vm.table.headers,"options":_vm.table.options,"items":_vm.merchandises,"search":_vm.table.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.department",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.department))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.name))])]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t('common.unit.' + String(item.unit) + '.long')))])]}},{key:"item.taxRates",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatTaxRates(item.taxRates)))])]}},{key:"item.expirationType",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t('purchase.merchandise.list.expirationType.' + String(item.expirationType))))])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.updatedAt)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('merchandise-history',{attrs:{"merchandise-id":item.id},on:{"fail":_vm.fail}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }