<template>
  <v-container id="table-merchandises" fluid tag="section">
    <v-card>
      <v-card-title>
        {{ $t('purchase.merchandise.list.title') }}
        <v-spacer />
        <v-text-field v-model="table.search" append-icon="mdi-magnify" :label="$t('purchase.merchandise.list.table.search')" single-line hide-details />
        <v-spacer />
        <table-exporter :headers="table.headers" :items="merchandises" />
        <table-printer element-id="table-merchandises" />
      </v-card-title>
      <v-data-table :headers="table.headers" :options="table.options" :items="merchandises" :search="table.search" :loading="loading" dense>
        <template #[`item.department`]="{ item }">
          <span class="text-capitalize">{{ item.department }}</span>
        </template>
        <template #[`item.name`]="{ item }">
          <span class="text-capitalize">{{ item.name }}</span>
        </template>
        <template #[`item.unit`]="{ item }">
          <span>{{ $t('common.unit.' + String(item.unit) + '.long') }}</span>
        </template>
        <template #[`item.taxRates`]="{ item }">
          <span>{{ formatTaxRates(item.taxRates) }}</span>
        </template>
        <template #[`item.expirationType`]="{ item }">
          <span>{{ $t('purchase.merchandise.list.expirationType.' + String(item.expirationType)) }}</span>
        </template>
        <template #[`item.updatedAt`]="{ item }">
          <span>{{ formatDate(item.updatedAt) }}</span>
        </template>
        <template #[`item.actions`]="{ item }">
          <merchandise-history :merchandise-id="item.id" @fail="fail" />
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { displayISO } from '@/util/DateUtil';
  import TableExporter from '@/views/components/common/TableExporter';
  import TablePrinter from '@/views/components/common/TablePrinter';
  import MerchandiseHistory from '@/views/components/business/purchase/MerchandiseHistory';

  export default {
    name: 'MerchandiseList',
    components: { MerchandiseHistory, TableExporter, TablePrinter },
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        loading: false,
        table: {
          headers: [
            {
              text: this.$i18n.t('purchase.merchandise.list.table.column.reference'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'reference'
            },
            {
              text: this.$i18n.t('purchase.merchandise.list.table.column.department'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'department'
            },
            {
              text: this.$i18n.t('purchase.merchandise.list.table.column.name'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'name'
            },
            {
              text: this.$i18n.t('purchase.merchandise.list.table.column.unit'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'unit'
            },
            {
              text: this.$i18n.t('purchase.merchandise.list.table.column.tax'),
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'taxRates'
            },
            {
              text: this.$i18n.t('purchase.merchandise.list.table.column.expirationType'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'expirationType'
            },
            {
              text: this.$i18n.t('purchase.merchandise.list.table.column.updatedAt'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'updatedAt'
            },
            {
              text: this.$i18n.t('purchase.merchandise.list.table.column.actions'),
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'actions'
            }
          ],
          options: {
            page: 1,
            itemsPerPage: -1,
            sortBy: ['name'],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            multiSort: false,
            mustSort: true
          },
          search: ''
        },
        merchandises: []
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      formatTaxRates (taxRates) {
        if (!Array.isArray(taxRates)) {
          return taxRates;
        }

        return taxRates.map((taxRate) => taxRate * 100).join('%, ') + '%';
      },
      formatDate: displayISO,
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'merchandises', { timeout: 10000 });
          if (response.data !== undefined && response.data.merchandises !== undefined) {
            this.merchandises = response.data.merchandises;
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('common.error.default'));
        }
        this.loading = false;
      },
      fail (error, errorMessage) {
        this.$emit('fail', error, errorMessage);
      }
    }
  };
</script>
